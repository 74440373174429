import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './styles/TournamentRegistration.css';
import HomeNavbar from '../../components/HomeNavbar'
import Footer from '../../components/Footer';
import {auth, database } from "../../config/firebaseConfig";
import { ref, get, set, push } from "firebase/database";
import Swal from "sweetalert2";
import axios from "axios";


const SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';
const BACKEND_URL = "https://cheda-gaming-api.onrender.com";

function TournamentRegistration() {

  useEffect(() => {
    document.title = 'Create New Tournament';
  }, [])

  //Section I
  const defaultImage = "https://images.pexels.com/photos/6759163/pexels-photo-6759163.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  const [hostUsername, setHostUsername] = useState(null);
  const [tournamentName, setTournamentName] = useState("");
  const [tournamentURL, setTournamentURL] = useState("");
  const [description, setDescription] = useState("");
  const [tournamentImage, setTournamentImage] = useState(defaultImage);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedGame, setSelectedGame] = useState('Call Of Duty');
  const [tournamentType, setTournamentType] = useState('Single Elimination');
  const [fee, setFee] = useState("");
  const [prize, setPrize] = useState("");
  const [location, setLocation] = useState("");
  const [onlineLocation, setOnlineLocation] = useState(true); 
  const [startDateTime, setStartDateTime] = useState("");
  const [loading, setLoading] = useState(false);

  const user = auth.currentUser
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.uid) {
      const userRef = ref(database, `Users/${user.uid}/username`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          setHostUsername(snapshot.val());
        }
      });
    }
  }, [user]);

  const gameDefaultImages = {
    "FC25": "https://media.contentapi.ea.com/content/dam/ea/fc/fc-24/common/gameplay/fc24-pre-order-founders.jpg.adapt.1456w.jpg",
    "NBA 2K": "https://assets.2k.com/1a6ngf98576c/5lvjCUPKs5vj2aVyzDobJh/ae2575e0e37c5e84d2e4b49978974384/N24-WEB-HOMEPAGE-RETAIL_CAROUSEL-KOBE_BRYANT_EDITION-MODULE_2-425x535-R2.jpg",
    "Tekken": "https://cdn.cloudflare.steamstatic.com/steam/apps/1778820/header.jpg?t=1692957440  a",
    "Call Of Duty": "https://www.callofduty.com/content/dam/atvi/callofduty/cod-touchui/blog/hero/mwiii/MWIII-REVEAL-FULL-TOUT.jpg",
    "Street Fighter": "https://www.ea.com/games/ea-sports-fc/fc-24/images/eyJrZXkiOiJwaGFzZS0yL2dhbWVzL2VhLXNwb3J0cy1mYy9mYy0yNC9fbmV4dC9zdGF0aWMvbWVkaWEvZ2FtZS1tb2Rlcy1jbHVicy4wYmY1ZGM5ZC5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjEyOdatabase9LCJ3ZWJwIjp7InF1YWxpdHkiOjkwLCJlZmZvcnQiOjV9fX0=",
    "PUBG": "https://www.ea.com/games/ea-sports-fc/fc-24/images/eyJrZXkiOiJwaGFzZS0yL2dhbWVzL2VhLXNwb3J0cy1mYy9mYy0yNC9fbmV4dC9zdGF0aWMvbWVkaWEvZ2FtZS1tb2Rlcy1jbHVicy4wYmY1ZGM5ZC5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjEyOdatabase9LCJ3ZWJwIjp7InF1YWxpdHkiOjkwLCJlZmZvcnQiOjV9fX0="
  };

  const handleGameChange = (e) => {
    const selected = e.target.value;
    setSelectedGame(selected);
  };
  const handleTournamentTypeChange = (e) => {
    const selected = e.target.value;
    setTournamentType(selected);
  };
  const handleLocationRadioChange = (e) => {
    if (e.target.value === "online") {
      setOnlineLocation(true);
    } else {
      setOnlineLocation(false);
    }
  };

  const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); 
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      Swal.fire("Error!", "No file selected. Please try again.", "error");
      return;
    }
  
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
  
    console.log("Form data:", formData.get("file"), formData.get("upload_preset"));
  
    try {
      setLoading(true);
  
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dwp2kii1e/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
  
      console.log("Upload response:", response.data);
      setImageUrl(response.data.secure_url);
    } catch (error) {
      console.error("Error uploading image:", error.response?.data || error.message);
      Swal.fire("Error!", "Image upload failed. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submission started");
  
    setLoading(true);
  
    try {
      // Ensure image upload is completed before proceeding
      if (imageUrl === "" && tournamentImage && tournamentImage !== defaultImage) {
        console.log("Uploading image...");
        await handleImageUpload();
      }
  
      // Check if tournament URL already exists
      const tournamentsRef = ref(database, 'HostedTournaments');
      const tournamentsSnapshot = await get(tournamentsRef);
      const tournaments = tournamentsSnapshot.exists() ? tournamentsSnapshot.val() : {};
      const urlExists = Object.values(tournaments).some(
        (t) => t.tournamentURL === tournamentURL
      );
  
      if (urlExists) {
        setLoading(false);
        Swal.fire({
          title: 'Error!',
          text: 'A tournament with this URL already exists. Please choose a different URL.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }
  
      console.log("Creating tournament entry...");
      const newTournamentRef = push(tournamentsRef);
      const tournamentId = newTournamentRef.key;
  
      const tournamentData = {
        tournamentId,
        host: user.uid,
        tournamentName,
        tournamentURL,
        description,
        tournamentImage: imageUrl || defaultImage,
        gameImageUrl: gameDefaultImages[selectedGame],
        selectedGame,
        tournamentType,
        rounds: "",
        fee,
        prize,
        location: onlineLocation ? 'online' : 'physical',
        physicalLocationAddress: !onlineLocation ? location : '',
        tournamentDate: startDateTime,
        tournamentCreationDate: getFormattedDate(),
      };
  
      const data = {
        to: user?.email,
        userName: hostUsername,
        tournamentLink: `https://www.chedagaming.com/tournaments/${tournamentURL}`,
      };
  
      await set(newTournamentRef, tournamentData);
      console.log("Tournament entry created in database");
  
      await Promise.all([
        sendSlackNotification(hostUsername, user.email),
        sendTournamentRegEmail(data),
      ]);
      console.log("Notifications sent");
  
      Swal.fire({
        title: 'Tournament Created Successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => navigate('/tournaments'));
  
    } catch (error) {
      console.error("Error during tournament creation:", error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while creating the tournament. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      console.log("Resetting loading state");
      setLoading(false);
    }
  };
  

  async function sendSlackNotification(username, email) {
    const payload = {
      text: `A new tournament was hosted! 🎉 by ${username}\n*Email:* ${email}`,
    };
  
    try {
      const response = await fetch(SLACK_WEBHOOK_URL, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        console.error('Slack notification failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error sending Slack notification:', error);
    }
  }

  const sendTournamentRegEmail = async (data) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/user/recurring-tournament-email`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending tournament email:', error);
    }
  };

  return (
    <>
      <HomeNavbar />
      <div className='tournament-container'>
        <div className="tournament-box">
          <h1 className="section-one-title">NEW TOURNAMENT</h1>
          <form className="tournaments-form" onSubmit={handleSubmit}>
              <div className="tournament-form-box">
                <label htmlFor="host-account">Host account</label>
                <p className="form-input-box">{hostUsername}</p>
              </div>

              <div className="tournament-form-box">
                <label htmlFor="tournamentName">Tournament name</label>
                <input
                  className="form-input-box tournaments-name"
                  type="text"
                  id="tournamentName"
                  placeholder="Enter your tournament name"
                  value={tournamentName}
                  onChange={(e) => setTournamentName(e.target.value)}
                  required />
              </div>

              <div className="tournament-form-box">
                <label htmlFor="tournament-url-input">Tournament URL</label>
                <div className="host-placeholder">
                  <span className="default-url">tournaments/</span>
                  <input
                    className="form-input-box"
                    type="text"
                    id="tournament-url-input"
                    placeholder=""
                    value={tournamentURL}
                    onChange={(e) => setTournamentURL(e.target.value)}
                    style={{ paddingLeft: "154px" }}
                    required
                  />
                </div>
              </div>

              <div className="tournament-form-box desc-form">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-input-box"
                  id="description"
                  name="description"
                  placeholder="Enter tournament description here"
                  rows="5"
                  defaultValue={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  style={{ minHeight: "153px", maxHeight: "153px", minWidth: "294px", maxWidth: "503px", paddingTop: "12px" }} />
              </div>

              <div className="tournament-form-box">
                <label htmlFor="tournament-image">Tournament image</label>
                <input
                  className="form-input-box image-upload"
                  type="file"
                  id="tournament-image"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
              <div className="tournament-form-box">
                <label htmlFor="game-dropdown">Tournament Type</label>
                <div className="host-placeholder">
                  <select
                    className="form-input-box"
                    id="tournament-type-dropdown"
                    value={tournamentType}
                    onChange={handleTournamentTypeChange}
                     >
                    <option value="Single Elimination">Single Elimination</option>
                    <option value="Double Elimination">Double Elimination</option>
                    {/* <option value="Round Robin">Round Robin</option> */}
                  </select>
                  <i className='fas fa-caret-down games-icon' />
                </div>
              </div>
              <div className="tournament-form-box">
                <label htmlFor="game-dropdown">Select a Game</label>
                <div className="host-placeholder">
                  <select
                    className="form-input-box"
                    id="game-dropdown"
                    value={selectedGame}
                    onChange={handleGameChange}
                     >
                    <option value="FC25">FC25</option>
                    <option value="NBA 2K">NBA 2K</option>
                    <option value="Tekken">Tekken</option>
                    <option value="Call Of Duty">Call Of Duty</option>
                    <option value="Street Fighter">Street Fighter</option>
                    <option value="PUBG">PUBG</option>
                  </select>
                  <i className='fas fa-caret-down games-icon' />
                </div>
              </div>

              <div className="tournament-form-box">
                <label className="tournament-url-input" htmlFor="registration-fee">Registration Fee</label>  
                <input
                  type="text"
                  className="form-input-box"
                  placeholder="Specify how much registration cost"
                  id="fee-amount"
                  value={fee}
                  onChange={(e) => setFee(e.target.value)}
                />
              </div>

              <div className="tournament-form-box">
                <label className="tournament-url-input" htmlFor="registration-fee">Prize Money</label>  
                <input
                  type="text"
                  className="form-input-box"
                  placeholder="Specify the prize money"
                  id="prize-amount"
                  value={prize}
                  onChange={(e) => setPrize(e.target.value)}
                />
              </div>

              <div className="registration-fee location-wrapper">
                <div className="reg-fee-div">
                  <label className="reg-fee" htmlFor="registration-fee">Tournament Location</label>
                </div>
                <br />
                <div className="free-paid-container">
                  <div className="radio-container">
                    <div
                      className={`radio-button ${onlineLocation ? "active" : ""}`}
                      onClick={() => handleLocationRadioChange({ target: { value: "online" } })}
                    >
                      <span>Online</span>
                    </div>
                    <div
                      className={`radio-button ${!onlineLocation ? "active" : ""}`}
                      onClick={() => handleLocationRadioChange({ target: { value: "physical" } })}
                    >
                      <span>Physical</span>
                    </div>
                  </div>
                  {onlineLocation ? null : (
                    <div className="paid-container location-container">
                      <input
                        type="text"
                        className="form-input-box"
                        placeholder="Kindly input your tournament locationt"
                        id="physical-location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="start-time-container">
                <label htmlFor="startDateTime">Start Time:</label>
                <input
                  className="form-input-box form-date-time"
                  type="datetime-local"
                  id="startDateTime"
                  name="startDateTime"
                  value={startDateTime}
                  onChange={(e) => setStartDateTime(e.target.value)}
                  required
                />
              </div>

              <button className="save-continue-btn" type="submit" disabled={loading}>{loading ? "Creating tournament..." : "Create Tournament"}</button>

          </form>

        </div>
      </div >

      <Footer />

    </>

  );
}

export default TournamentRegistration;