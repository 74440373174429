import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from './DashboardPage';
import DashboardNavbar from '../../components/DashboardNavbar'
import './UserDashboard.css';


function UserDashboard() {
  useEffect(() => {
    document.title = 'Dashboard'
  }, []);
  
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return (

    <div className="dashboard-body">
    <DashboardNavbar/>
    <Dashboard/>
    </div>

  );
}

export default UserDashboard;
