import React, { useEffect, useState } from "react";
import { ref, get } from "firebase/database";
import {auth, database } from "../../config/firebaseConfig"; 
import './DashboardPage.css';
import '../BrowseGames.css';
import { Link } from "react-router-dom";
import moment from "moment";

function DashboardPage () {
  const [joinedTournaments, setJoinedTournaments] = useState([]);
  const [hostedTournaments, setHostedTournaments] = useState([]);
  const [isLoadingJoined, setIsLoadingJoined] = useState(true);
  const [isLoadingHosted, setIsLoadingHosted] = useState(true);
  const currentUserId = auth.currentUser?.uid;

  useEffect(() => {
    const fetchJoinedTournaments = async () => {
      try {
        const tournamentsRef = ref(database, "HostedTournaments");
        const snapshot = await get(tournamentsRef);
  
        if (snapshot.exists()) {
          const tournamentsData = snapshot.val();
  
          // Fetch tournaments and host usernames
          const tournamentsArray = await Promise.all(
            Object.keys(tournamentsData).map(async (key) => {
              const tournament = { id: key, ...tournamentsData[key] };
  
              // Fetch the username for the host
              const userRef = ref(database, `Users/${tournament.host}`);
              const userSnapshot = await get(userRef);
  
              if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                tournament.hostUsername = userData.username || "Unknown Host";
              } else {
                tournament.hostUsername = "Unknown Host";
              }
  
              return tournament;
            })
          );
  
          // Filter tournaments the current user has joined
          const joined = tournamentsArray.filter((tournament) =>
            tournament.participants?.includes(currentUserId)
          );
  
          setJoinedTournaments(joined);
        } else {
          console.log("No tournaments found.");
        }
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      } finally {
        setIsLoadingJoined(false);
      }
    };
  
    fetchJoinedTournaments();
  }, [currentUserId]);

  useEffect(() => {
    // Fetch Hosted Tournaments
    const fetchHostedTournaments = async () => {
      try {
        const tournamentsRef = ref(database, "HostedTournaments");
        const snapshot = await get(tournamentsRef);

        if (snapshot.exists()) {
          const tournamentsData = snapshot.val();

          const tournamentsArray = await Promise.all(
            Object.keys(tournamentsData).map(async (key) => {
              const tournament = { id: key, ...tournamentsData[key] };

              // Fetch the username for the host
              const userRef = ref(database, `Users/${tournament.host}`);
              const userSnapshot = await get(userRef);

              if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                tournament.hostUsername = userData.username || "Unknown Host";
              } else {
                tournament.hostUsername = "Unknown Host";
              }

              return tournament;
            })
          );

          const hosted = tournamentsArray.filter(
            (tournament) => tournament.host === currentUserId
          );

          setHostedTournaments(hosted);
        } else {
          console.log("No tournaments found.");
        }
      } catch (error) {
        console.error("Error fetching hosted tournaments:", error);
      } finally {
        setIsLoadingHosted(false);
      }
    };

    fetchHostedTournaments();
  }, [currentUserId]);
  
  
  return (
    <div className="dashboard-container">

      <div>
        <h2 className="joined_title">Joined Tournaments</h2>
        {isLoadingJoined ? (
          <p>Loading...</p>
        ) : joinedTournaments.length === 0 ? (
          <div className="no-tournaments">
            <p className="live_parag">No joined tournaments available.</p>
            <Link className="create-tournaments-link" to="/tournaments">
              <button className="create-tournaments-btn">Browse Tournaments</button>
            </Link>
          </div>
        ) : (
          <div className="list -four">
            {joinedTournaments.map((tournament, idx) => (
              <div className="search-result-card" key={idx}>
                <div className="header">
                  <img src={tournament.tournamentImage} alt="" />
                </div>
                <div className="body">
                  <div className="user-details">
                    <div className="img">
                      <img
                        src={tournament.gameImageUrl}
                        alt={tournament.selectedGame}
                        className="user-avatar -smed"
                      />
                    </div>
                    <div className="name -break">
                      <h3>
                        <strong>{tournament.tournamentName}</strong>
                      </h3>
                    </div>
                  </div>
                  <ul className="list-details">
                    <li>
                      <i className="icon fa fa-user"></i>
                      <span className="text">{tournament.hostUsername}</span>
                    </li>
                    <li>
                      <i className="icon fa fa-gamepad"></i>
                      <span className="text">{tournament.selectedGame}</span>
                    </li>
                    <li>
                      <i className="icon fa fa-users"></i>
                      <span className="text">Participants: {tournament.participants?.length || 0}</span>
                    </li>
                    <li>
                      <div className="text">
                        <i className="icon fa fa-money-bill-wave"></i>
                        <strong>Prize: KSH {tournament.prize}</strong>
                      </div>
                    </li>
                    <li>
                      <div className="text">
                        <i className="icon fa fa-money-bill-wave"></i>Fee: KSH {tournament.fee}
                      </div>
                    </li>
                    <li>
                      <i className="icon fa fa-calendar"></i>
                      <span className="text">{moment(tournament.tournamentDate).format("MMMM D, YYYY h:mm A")}</span>
                    </li>
                    <li>
                      {tournament.location === "online" ? (
                        <div className="text">
                          <i className="icon fa fa-map-marker-alt"></i>Online
                        </div>
                      ) : (
                        <div className="text">
                          <i className="icon fa fa-map-marker-alt"></i>Physical
                     </div>
                      )}
                    </li>
                  </ul>
                  <div className="actions">
                    <Link className="btn btn-block btn-default" to={`/tournaments/${tournament.tournamentURL}`}>
                      View
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Section for Hosted Tournaments */}
      <div >
        <h2 className="joined_title">Hosted Tournaments</h2>
        {isLoadingHosted ? (
          <p>Loading...</p>
        ) : hostedTournaments.length === 0 ? (
          <div className="no-tournaments">
            <p className="live_parag">No hosted tournaments available.</p>
            <Link className="create-tournaments-link" to="/create-tournament">
              <button className="create-tournaments-btn">Create Tournament</button>
            </Link>
          </div>
        ) : (
          <div className="list -four">
            {hostedTournaments.map((tournament, idx) => (
              <div className="search-result-card" key={idx}>
              <div className="header">
                <img src={tournament.tournamentImage} alt="" />
              </div>
              <div className="body">
                <div className="user-details">
                  <div className="img">
                    <img
                      src={tournament.gameImageUrl}
                      alt={tournament.selectedGame}
                      className="user-avatar -smed"
                    />
                  </div>
                  <div className="name -break">
                    <h3>
                      <strong>{tournament.tournamentName}</strong>
                    </h3>
                  </div>
                </div>
                <ul className="list-details">
                  <li>
                    <i className="icon fa fa-user"></i>
                    <span className="text">{tournament.hostUsername}</span>
                  </li>
                  <li>
                    <i className="icon fa fa-gamepad"></i>
                    <span className="text">{tournament.selectedGame}</span>
                  </li>
                  <li>
                    <i className="icon fa fa-users"></i>
                    <span className="text">Participants: {tournament.participants?.length || 0}</span>
                  </li>
                  <li>
                    <div className="text">
                      <i className="icon fa fa-money-bill-wave"></i>
                      <strong>Prize: KSH {tournament.prize}</strong>
                    </div>
                  </li>
                  <li>
                    <div className="text">
                      <i className="icon fa fa-money-bill-wave"></i>Fee: KSH {tournament.fee}
                    </div>
                  </li>
                  <li>
                    <i className="icon fa fa-calendar"></i>
                    <span className="text">{moment(tournament.tournamentDate).format("MMMM D, YYYY h:mm A")}</span>
                  </li>
                  <li>
                    {tournament.location === "online" ? (
                      <div className="text">
                        <i className="icon fa fa-map-marker-alt"></i>Online
                      </div>
                    ) : (
                      <div className="text">
                        <i className="icon fa fa-map-marker-alt"></i>Physical
                   </div>
                    )}
                  </li>
                </ul>
                <div className="actions">
                  <Link className="btn btn-block btn-default"   to={{pathname: `/fixtures/${tournament.tournamentURL}`, state: { tournamentId: tournament.tournamentId }}}>
                    Update Tournament
                  </Link>
                </div>
              </div>
            </div>
            ))}
          </div>
        )}
      </div>
    </div>

  );
}

export default DashboardPage;
