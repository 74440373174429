import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ref, get, update, onValue  } from "firebase/database";
import {auth, database } from "../../config/firebaseConfig";
import "./TournamentUpdate.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HomeNavbar from '../../components/HomeNavbar';

const TournamentUpdate = () => {
  const location = useLocation();
  // const { tournamentId } = useParams();
  const { tournamentURL } = useParams()
  const [tournament, setTournament] = useState(null);
  const [participantNames, setParticipantNames] = useState({});
  const [participantImages, setParticipantImages] = useState({});
  const [participantEmail, setParticipantEmail] = useState({});
  const [roundsData, setRoundsData] = useState([]);
  const [tournamentType, setTournamentType] = useState(tournament?.tournamentType || "Single Elimination");
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);


  const currentUser = auth.currentUser; 

  useEffect(() => {
    if (currentUser) {
      setCurrentUserId(currentUser.uid); 
    }
  }, [currentUser]);

  // useEffect(() => {
  //   const tournamentRef = ref(database, `HostedTournaments/${tournamentId}`);

  //   // Set up a listener to get real-time data for the tournament
  //   const unsubscribe = onValue(tournamentRef, (snapshot) => {
  //     if (snapshot.exists()) {
  //       const fetchedTournament = snapshot.val();
  //       setTournament(fetchedTournament);
  //       setTournamentType(fetchedTournament.tournamentType);
  //       setIsLoading(false); // Stop loading once data is fetched
  //     } else {
  //       console.error("Tournament not found.");
  //       setIsLoading(false);
  //     }
  //   });

  //   // Cleanup listener when the component is unmounted or tournamentId changes
  //   return () => unsubscribe();
  // }, [tournamentId]);

  useEffect(() => {
    if (!tournamentURL) {
      console.error("tournamentURL is required but missing!");
      setIsLoading(false);
      return;
    }

    // Fetch tournament details using tournamentURL
    const tournamentRef = ref(database, `HostedTournaments`);
    const unsubscribe = onValue(tournamentRef, (snapshot) => {
      if (snapshot.exists()) {
        const tournaments = snapshot.val();
        // Find the tournament with the matching URL
        const fetchedTournament = Object.values(tournaments).find(
          (tournament) => tournament.tournamentURL === tournamentURL
        );

        if (fetchedTournament) {
          setTournament(fetchedTournament);
          setTournamentType(fetchedTournament.tournamentType);
        } else {
          console.error("Tournament not found with the provided URL.");
        }
      } else {
        console.error("No tournaments found.");
      }
      setIsLoading(false);
    });

    // Cleanup listener when the component is unmounted or tournamentURL changes
    return () => unsubscribe();
  }, [tournamentURL]);

  useEffect(() => {
        const fetchAllRoundsData = async () => {
        if (tournament && (tournamentType === "Single Elimination" || tournamentType === "Double Elimination")) {
            try {
            const rounds = tournament.rounds || {};
            const fetchedRoundsData = [];
            for (const [roundKey, roundMatches] of Object.entries(rounds)) {
                const matches = Object.entries(roundMatches)
                // .filter(([key]) => key !== "previousOddParticipant")
                .map(([matchId, match]) => {
                const participants = Object.keys(match);
                return {
                    id: matchId,
                    participant1: participants[0] || null,
                    score1: participants[0] ? match[participants[0]].score : null,
                    participant2: participants[1] || null,
                    score2: participants[1] ? match[participants[1]].score : null,
                };
                });
                fetchedRoundsData.push({ round: roundKey, matches });
            }
            setRoundsData(fetchedRoundsData);

            // Fetch participant details
            const allParticipants = [
                ...new Set(
                fetchedRoundsData.flatMap((round) =>
                    round.matches.flatMap((match) => [match.participant1, match.participant2])
                )
                ),
            ].filter(Boolean);

            const participantPromises = allParticipants.map(async (id) => {
                const userRef = ref(database, `Users/${id}`);
                const userSnapshot = await get(userRef);
                if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                return { id, name: userData.username, imageUrl: userData.imageUrl, email: userData.email || null };
                }
                return { id, name: "Unknown", imageUrl: null, email: 'Unknown' };
            });

            const participants = await Promise.all(participantPromises);
            const nameMap = {};
            const imageMap = {};
            const emailMap = {};
            participants.forEach((user) => {
                nameMap[user.id] = user.name;
                imageMap[user.id] = user.imageUrl;
                emailMap[user.id] = user.email;
            });

            setParticipantNames(nameMap);
            setParticipantImages(imageMap);
            setParticipantEmail(emailMap);
            } catch (error) {
            console.error("Error fetching rounds data:", error);
            }
        }
        };

        fetchAllRoundsData();
  }, [tournament, tournamentType]);
  
  const isHost = tournament?.host === currentUserId;

  if (tournamentType !== "Single Elimination" && tournamentType !== "Double Elimination") {
    return <p>Bracket view is only available for Single and Double Elimination tournaments.</p>;
  }

  const handleScoreChange = (roundIndex, matchId, participantId, score) => {
    setRoundsData((prevRounds) =>
      prevRounds.map((round, index) =>
        index === roundIndex
          ? {
              ...round,
              matches: round.matches.map((match) =>
                match.id === matchId
                  ? {
                      ...match,
                      [participantId === match.participant1 ? "score1" : "score2"]: score,
                    }
                  : match
              ),
            }
          : round
      )
    );
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const getAvatarUrl = (name, imageUrl) => {
    if (imageUrl) {
      return imageUrl;
    }
    // Use a cartoon avatar if the imageUrl is null or empty
    return `https://robohash.org/${name}?set=set2&size=200x200`;
  };

  
  const handleUpdateRound = async (roundIndex) => {
    const round = roundsData[roundIndex];
    const updates = {};
    const roundKey = round.round;
  
    // Update scores for the current round
    round.matches.forEach((match) => {
      if (match.score1 !== null && match.score1 !== "") {
        updates[`HostedTournaments/${tournament?.tournamentId}/rounds/${roundKey}/${match.id}/${match.participant1}/score`] =
          match.score1;
      }
      if (match.score2 !== null && match.score2 !== "") {
        updates[`HostedTournaments/${tournament?.tournamentId}/rounds/${roundKey}/${match.id}/${match.participant2}/score`] =
          match.score2;
      }
    });
  
    // Check for a single match with one participant in the round
    if (
      round.matches.length === 1 &&
      round.matches[0].participant1 &&
      !round.matches[0].participant2
    ) {
      const winner = {
        id: round.matches[0].participant1,
        name: participantNames[round.matches[0].participant1],
      };
  
      // Update the winner in the next round
      // const nextRoundKey = `round${roundIndex + 2}`;
      // updates[`HostedTournaments/${tournamentId}/rounds/${nextRoundKey}`] = {
      //   winnerId: winner.id,
      //   winnerName: winner.name,
      // };
  
      // Send emails
      // try {
      //   // Notify all participants
      //   await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/tournament-email`, {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify({ tournamentId, winner }),
      //   });
  
        alert("Emails sent successfully!");
      // } catch (error) {
      //   console.error("Error sending emails:", error);
      //   alert("Error sending emails. Please try again.");
      // }
    } else {
      // Logic for multiple matches (next round creation)
      const winners = round.matches
        .map((match) => {
          if (match.score1 > match.score2) {
            return { id: match.participant1, name: participantNames[match.participant1] };
          }
          if (match.score2 > match.score1) {
            return { id: match.participant2, name: participantNames[match.participant2] };
          }
          return null;
        })
        .filter(Boolean);
  
      const nextRoundKey = roundsData[roundIndex + 1]?.round || `round${roundIndex + 2}`;
      const nextRoundMatches = [];
  
      for (let i = 0; i < winners.length; i += 2) {
        const participant1 = winners[i];
        const participant2 = winners[i + 1] || null;
  
        const matchId = `match${Math.floor(i / 2) + 1}`;
        updates[`HostedTournaments/${tournament?.tournamentId}/rounds/${nextRoundKey}/${matchId}/${participant1.id}/score`] = "";
  
        if (participant2) {
          updates[`HostedTournaments/${tournament?.tournamentId}/rounds/${nextRoundKey}/${matchId}/${participant2.id}/score`] = "";
        } else {
          updates[`HostedTournaments/${tournament?.tournamentId}/rounds/${nextRoundKey}/${matchId}/${participant1.id}/score`] = 3;
        }
  
        nextRoundMatches.push({ matchId, participant1, participant2 });
      }
  
      if (!roundsData[roundIndex + 1]) {
        roundsData.push({
          round: nextRoundKey,
          matches: nextRoundMatches,
        });
      }
    }
  
    // Update Firebase
    if (Object.keys(updates).length > 0) {
      try {
        await update(ref(database), updates);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `${roundKey} updated successfully!`,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
        // alert(`${roundKey} updated successfully!`);
        // window.location.reload();
      } catch (error) {
        console.error("Error updating round:", error);
        alert("Error updating round. Please try again.");
      }
    } else {
      alert("No scores have changed.");
    }
  };
  
  
  
  

  return (
    <>
    {/* <HomeNavbar /> */}
    <div className="tournament-update-container">    
      <h1>{tournament?.tournamentName} Fixtures Table; {tournament?.tournamentType}</h1>

      <div className="round">
        {roundsData.map((round, roundIndex) => (
          <div key={round.round} className="round-container">
            <div className="fixtures-update">
              <div className="fixture-container">
                {round.matches.map((match, index) => (
                  <div key={match.id} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <p>{index + 1}</p>
                    <div className="match">
                      <div className={`first player ${!match.participant2 ? "single-participant" : ""}`}>
                        <div className="player-info">
                          <img
                            src={getAvatarUrl(participantNames[match.participant1] || "Unknown", participantImages[match.participant1])}
                            alt={participantNames[match.participant1] || "Unknown"}
                            className="avatar"
                            />
                          <span className="player-name">{participantNames[match.participant1] || "Unknown"}</span>
                        </div>
                        <div className="first-score">
                        {isHost && (
                          <input
                            type="number"
                            value={match.score1 || ""}
                            onChange={(e) => handleScoreChange(roundIndex, match.id, match.participant1, e.target.value)}
                            className="score-input"
                          />
                        )}
                          {!isHost && (
                          <span className="score-input">{match.score1 || ""}</span>
                          )}
                        </div>
                      </div>
                      {match.participant2 && (
                        <div className="second player">
                          <div className="player-info">
                            <img
                              src={getAvatarUrl(participantNames[match.participant2] || "Unknown", participantImages[match.participant2])}
                              alt={participantNames[match.participant2] || "Unknown"}
                              className="avatar"
                            />
                            <span className="player-name">{participantNames[match.participant2] || "Unknown"}</span>
                          </div>
                          <div className="second-score">
                          {isHost && (
                            <input
                              type="number"
                              value={match.score2 || "-"}
                              onChange={(e) => handleScoreChange(roundIndex, match.id, match.participant2, e.target.value)}
                              className="score-input"
                            />
                          )}

                          {!isHost && (
                            <span className="score-input">{match.score2 || "-"}</span>
                          )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {isHost && (
                <button
                  className="update-btn-mobile"
                  onClick={() => handleUpdateRound(roundIndex)}
                  disabled={
                    round.matches.length === 1 &&
                    round.matches[0].participant1 &&
                    !round.matches[0].participant2
                  }
                >
                  {round.matches.length === 1 &&
                  round.matches[0].participant1 &&
                  !round.matches[0].participant2
                    ? "Winner"
                    : `Update ${round.round}`}
                </button>
              )}
              {!isHost && (
                <button
                  className="update-btn-mobile"
                >
                  {round.matches.length === 1 &&
                  round.matches[0].participant1 &&
                  !round.matches[0].participant2
                    ? "Winner"
                    : `Update ${round.round}`}
                </button>
              )}
              {roundIndex < roundsData.length - 1 && (
                <div className="arrow"></div>
              )}
            </div>
            {isHost && (
            <button
              className="update-btn"
              onClick={() => handleUpdateRound(roundIndex)}
              disabled={
                round.matches.length === 1 &&
                round.matches[0].participant1 &&
                !round.matches[0].participant2
              }
            >
              {round.matches.length === 1 &&
              round.matches[0].participant1 &&
              !round.matches[0].participant2
                ? "Winner"
                : `Update ${round.round}`}
            </button>
            )}
            {!isHost && (
            <button
              className="update-btn"
            >
              {round.matches.length === 1 &&
              round.matches[0].participant1 &&
              !round.matches[0].participant2
                ? "Winner"
                : `Update ${round.round}`}
            </button>
            )}

          </div>
                
        ))}
      </div>

    </div>
    </>
  );
  
};

export default TournamentUpdate;
